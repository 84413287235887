export default {
  hello: {
    tag: 'Welcome',
    image: 'card-hello.png',
    video: null,
    personaly: true,
    headline: 'infoCards.hello.headline',
    subline: 'infoCards.hello.subline',
    body: 'infoCards.hello.body'
  },
  monitored: {
    tag: 'Welcome',
    image: 'card-stats.png',
    video: null,
    personaly: true,
    headline: 'infoCards.monitored.headline',
    subline: 'infoCards.monitored.subline',
    body: 'infoCards.monitored.body'
  },
  chat: {
    tag: 'Welcome',
    image: 'card-tawkto.png',
    video: null,
    personaly: true,
    headline: 'infoCards.chat.headline',
    subline: 'infoCards.chat.subline',
    body: 'infoCards.chat.body',
    link: {
      label: 'infoCards.chat.linkLabel',
      de: '/dashboard?newProject=true',
      en: '/dashboard?newProject=true'
    }
  },
  first: {
    tag: 'Congratulation',
    image: 'cardPicture-first.jpg',
    video: null,
    personaly: true,
    headline: 'infoCards.first.headline',
    subline: 'infoCards.first.subline',
    body: 'infoCards.first.body'
  },
  addComponents: {
    tag: 'Congratulation',
    image: 'card-add-components.png',
    video: null,
    personaly: false,
    headline: 'infoCards.addComponents.headline',
    subline: 'infoCards.addComponents.subline',
    body: 'infoCards.addComponents.body'
  },
  lookAround: {
    tag: 'Congratulation',
    image: 'card-look-around.png',
    video: null,
    personaly: false,
    headline: 'infoCards.lookAround.headline',
    subline: 'infoCards.lookAround.subline',
    body: 'infoCards.lookAround.body'
  },
  projectAdded: {
    tag: 'infoCards.headlines.project_created',
    image: 'card-project-urls.png',
    video: null,
    personaly: false,
    headline: 'infoCards.projectAdded.headline',
    subline: 'infoCards.projectAdded.subline',
    body: 'infoCards.projectAdded.body'
  },
  componentFinder: {
    tag: 'infoCards.headlines.project_created',
    image: 'card-project-urls.png',
    video: null,
    personaly: false,
    headline: 'infoCards.componentFinder.headline',
    subline: 'infoCards.componentFinder.subline',
    body: 'infoCards.componentFinder.body',
    link: {
      notExact: true,
      label: 'infoCards.componentFinder.linkLabel',
      de: '?openWizard=true',
      en: '?openWizard=true'
    }
  }
}
