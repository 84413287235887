import { listNavigationAliases } from '@/domain/navigationTools'

const navigation = [
  {
    id: 'dashboard',
    label: 'pages.dashboard',
    icon: 'house',
    alias: true,
    isHidden: () => false,
    description: 'navDescription.dashboard',
    groups: [
      {
        groupName: 'pages.projects',
        id: 'projects',
        entries: [
          {
            alias: true,
            id: 'monitoring360Overview',
            path: '/dashboard',
            name: 'pages.projectsOverview',
            key: 'dashboard',
            isHidden: true
          }
        ]
      }]
  },
  {
    id: 'blocklist',
    label: 'pages.blocklist',
    icon: 'blocklist',
    alias: true,
    description: 'navDescription.blocklist',
    groups: [
      {
        groupName: 'pages.blocklist',
        id: 'blocklist',
        entries: [
          {
            alias: true,
            id: 'blocklistOverview',
            name: 'pages.blocklistOverview',
            path: '/blocklist',
            pageName: 'Blocklist Monitoring',
            key: 'blocklist',
            breadcrumb: ['blocklist', 'blocklistOverview']
          }
        ]
      }
    ]
  },
  {
    id: 'user',
    label: 'pages.user',
    icon: 'user',
    alias: true,
    description: 'navDescription.user',
    groups: [
      {
        groupName: 'pages.user',
        id: 'user',
        entries: [
          {
            alias: true,
            notInNav: true,
            pageName: 'Account Details',
            id: 'userProfile',
            name: 'pages.userProfile',
            path: '/user',
            key: 'user',
            breadcrumb: ['user', 'userProfile']
          }
        ]
      },
      {
        groupName: 'pages.license',
        id: 'license',
        entries: [
          {
            alias: true,
            notInNav: true,
            pageName: 'License Management',
            id: 'userLicense',
            name: 'pages.userLicense',
            path: '/user/license',
            key: 'user-license',
            breadcrumb: ['user', 'userLicense']
          }
        ]
      }
    ]
  },
  {
    id: 'crawler',
    label: 'pages.crawler',
    icon: 'search',
    alias: true,
    description: 'navDescription.overview',
    groups: [
      {
        groupName: 'pages.crawler',
        id: 'Full Site Check',
        entries: [
          {
            alias: true,
            id: 'overviewCrawler',
            name: 'pages.overviewCrawler',
            path: '/crawler/',
            key: 'crawler',
            breadcrumb: ['crawler', 'overviewCrawler']
          },
          {
            alias: true,
            id: 'recentCrawler',
            name: 'pages.recentCrawler',
            path: '/crawler/recent/',
            key: 'crawler-recent',
            breadcrumb: ['crawler', 'overviewCrawler', 'recentCrawler']
          },
          {
            alias: true,
            notInNav: true,
            id: 'recentCrawler',
            name: 'pages.recentCrawlerDetails',
            path: '/crawler/settings/',
            key: 'crawler-recent-id',
            breadcrumb: ['crawler', 'overviewCrawler', 'crawlerSettings']
          },
          {
            alias: true,
            id: 'scheduledCrawls',
            name: 'pages.scheduledCrawls',
            path: '/crawler/scheduledCrawls/',
            key: 'crawler-scheduledCrawls',
            breadcrumb: ['crawler', 'overviewCrawler', 'scheduledCrawls'],
            salesLabel: {
              label: 'Business',
              condition: plan => ['lite', 'pro'].includes(plan),
              contentId: 'customer.360.business.scheduled-crawls'
            }
          },
          {
            alias: true,
            notInNav: true,
            id: 'editScheduledCrawl',
            name: 'pages.editScheduledCrawl',
            path: '/crawler/scheduledCrawls/edit',
            key: 'crawler-scheduledCrawls-edit',
            breadcrumb: ['crawler', 'overviewCrawler', 'scheduledCrawls']
          },
          {
            alias: true,
            notInNav: true,
            id: 'createScheduledCrawl',
            name: 'pages.createScheduledCrawl',
            path: '/crawler/scheduledCrawls/create',
            key: 'crawler-scheduledCrawls-create',
            breadcrumb: ['crawler', 'overviewCrawler', 'scheduledCrawls']
          },
          {
            alias: true,
            id: 'runCrawler',
            name: 'pages.runCrawler',
            path: '/crawler/run/',
            key: 'crawler-run',
            breadcrumb: ['crawler', 'overviewCrawler', 'runCrawler']
          },
          {
            alias: true,
            notInNav: true,
            id: 'runCrawler',
            name: 'pages.runCrawlerSetup',
            path: '/crawler/run/',
            key: 'crawler-run-id',
            breadcrumb: ['crawler', 'overviewCrawler', 'runCrawler']
          }
        ]
      }
    ]
  }
]

const scoreDetailLinks = {
  'Score:Basic:Uptime': 'uptimeOverview',
  'Score:Basic:Performance': 'performanceOverview',
  'Score:Basic:Seo': 'seoOverview',
  'Score:Basic:Content': 'contentOverview',
  'Score:Basic:Tech': 'technicalOverview',
  'Score:Basic:Security': 'securityOverview',
  'Score:Uptime:Down': 'uptimeStats',
  'Score:Uptime:Uptime': 'uptimeStats',
  'Score:Performance:TimeToFirstByte': 'performanceServertimings',
  'Score:Performance:TimeToLoad': 'performanceBrowsertimings',
  'Score:Performance:BigComponents': 'performancePagesize',
  'Score:Performance:BigResources': 'performancePagesize',
  'Score:Performance:Lighthouse': 'performanceLighthouse',
  'Score:Seo:Lighthouse': 'seoScore',
  'Score:Seo:Sitemap': 'seoSitemapMobile',
  'Score:Seo:Mobilefriendly': 'seoSitemapMobile',
  'Score:Content:Deadlink': 'contentBroken',
  'Score:Content:Brokenresources': 'contentBroken',
  'Score:Tech:Javascriptinternal': 'technicalJavascript',
  'Score:Tech:Javascriptexternal': 'technicalJavascript',
  'Score:Security:Certificate': 'securityCerts',
  'Score:Security:Cookiecount': 'securityCookies',
  'Score:Security:Insecurecontent': 'securityElements'
}

const navAliases = listNavigationAliases(navigation)

const initialNav = navAliases.overviewCrawler.path

export {
  navAliases,
  navigation,
  initialNav,
  scoreDetailLinks
}
