export default {
  localConfig: {
    login: ['default'],
    useSessionLogin: false,
    ssoLoginUrl: 'https://platform360.io',
    gtmId: 'thisIsATest',
    id: '63dd207def79d6a82dbe4f7e',
    companyName: '360 Monitoring Stage',
    slug: '360monitoring-app-stage',
    tenantRealm: 'local',
    logoUrl: 'http://app.stage.360monitoring.com/img/360monitoring.svg',
    faviconUrl: 'https://app.stage.360monitoring.com/favicon.ico',
    storeUrl: 'https://app.stage.360monitoring.com/sitecheck/user/license/',
    cname: 'app.stage.360monitoring.com',
    authHost: 'https://app.stage.360monitoring.com',
    authCname: null,
    licenseCname: null,
    koalityCname: null,
    sessionDomain: 'app.stage.360monitoring.com',
    consentUrl: 'https://360monitoring.com/legal/#privacy-policy',
    imprintUrl: 'https://360monitoring.com/imprint',
    termsUrl: 'https://360monitoring.com/legal/#terms-of-service',
    standaloneAuthRedirectUrl: null,
    standaloneLicensingRedirectUrl: null,
    standaloneServiceRedirectUrl: null,
    afterLogoutRedirectUrl: 'https://app.stage.360monitoring.com/',
    brandColor: null,
    primaryColor: null,
    secondaryColor: null,
    headerColor: null,
    ssoSignInUrl: null,
    ssoSignUpUrl: null,
    ssoSignOutUrl: null,
    ssoAuthStrategy: null,
    partnerType: 'standalone',
    supportEmail: 'support@360monitoring.com',
    signUpWithLicense: false,
    appHeaderModel: 'standaloneThreeSixtyNixstats',
    fscTenant: '360',
    fscUser: '360monitoring',
    fscPassword: 'def5020073b79e9402bb4e79947611402f14ef13eefe6e6eba4853cddcbca738ac25866b269f4079e2ebb00ebc2b3d06a4017a0ae8607c4d239404b19e3a8a1c3e5ad30fef4fbc0931e533d676ed95ff40fbcc9a335a9ac8c59093bf95bcee0a1f4f10c37fcbed87b91f1e7373c77c20d19eecb794ca6f728ee28e951ad9da0dc16f04132c4c8caf8ee4ebcdbfc141f84d77a84ced4ac3e0e3986a2cacb4625b5cbeace161b71eef3dabb7fabf1dcf8bddcd3ba82384e5fb9cefce3296a3cf2f0c0101815fa5a4adc39cccf834911162475a579ca4f3fd7e12a9e4d29e0fc895c14b6391645f6f23c7f77b809a4f7e6cdbce3f7d4d86439552867b93ea4a59bd48599be1ffab2a7242d354e5b9c141aa6ab7ee90f70ecf04cfef4cf23146299c3d8a8862f121133975c62625200d7d649f4bb60a8798815606d1e31d0fb1c600aab563b1191e9c91fa144bfee9e847469249b6fa9420c7af53982a9df6db88b3',
    customHtml: '<style>:root {--tsxahBackground: #fff !important; --tsxahNavBorderActive: #003694 !important; --tsxahNavBorderHover: #003694 !important;</style>',
    smtpSettings: null,
    validSMTPSettings: false,
    environment: 'stage',
    licensePartnerId: null,
    liveChatLicenseKey: '7680561',
    liveChatGroupId: '15',
    websitesEnabled: true,
    fscEnabled: true
  },
  'plesk.koality.io': {
    login: ['default', 'haendlerbund', 'google'],
    horizontalNavigation: true,
    metaLinks: {
      imprint: '',
      privacy: '',
      gtc: ''
    }
  },
  'sitecheck.platform360.io': {
    login: ['default'],
    useSessionLogin: true,
    ssoLoginUrl: 'https://platform360.io',
    metaLinks: {
      imprint: '',
      privacy: '',
      gtc: ''
    }
  },
  'app.stage.360monitoring.com': {
    login: ['default'],
    useSessionLogin: true,
    ssoLoginUrl: 'https://app.stage.360monitoring.com',
    metaLinks: {
      imprint: '',
      privacy: '',
      gtc: ''
    }
  },
  'app.360monitoring.com': {
    login: ['default'],
    useSessionLogin: true,
    ssoLoginUrl: 'https://app.360monitoring.com',
    metaLinks: {
      imprint: '',
      privacy: '',
      gtc: ''
    }
  },
  'monitoring.platform360.io': {
    login: ['default'],
    useSessionLogin: true,
    ssoLoginUrl: 'https://platform360.io',
    metaLinks: {
      imprint: '',
      privacy: '',
      gtc: ''
    }
  },
  // 'feature-sessionhandling.branches.360.koality.io': {
  //   login: ['default'],
  //   useSessionLogin: true,
  //   ssoLoginUrl: 'https://monitoring.platform360.staging.plesk.tech/signin?redirectUrl=',
  //   metaLinks: {
  //     imprint: '',
  //     privacy: '',
  //     gtc: ''
  //   }
  // },
  'develop.branches.360.koality.io': {
    login: ['default'],
    useSessionLogin: true,
    ssoLoginUrl: 'https://monitoring.platform360.staging.plesk.tech/signin?redirectUrl=',
    metaLinks: {
      imprint: '',
      privacy: '',
      gtc: ''
    }
  },
  'feature-sessionhandling.branches.360.koality.io': {
    login: ['default'],
    cname: 'sitecheck.platform360.io',
    companyName: 'My Hosting',
    partnerType: 'partner',
    tenantRealm: 'myhosting',
    signUpWithLicense: false,
    slug: 'myhosting',
    ssoAuthStrategy: null,
    ssoSignInUrl: null,
    ssoSignOutUrl: null,
    ssoSignUpUrl: null,
    useSessionLogin: false,
    ssoLoginUrl: '',
    storeUrl: 'http://shop.my-hosting.world',
    isPartner: true,
    baseUrl: {
      dev: 'http://localhost:3000',
      stage: 'http://localhost:3000',
      production: 'http://localhost:3000'
    },
    externalWlDomain: 'https://monitoring.my-hosting.world',
    metaLinks: {
      imprint: '',
      privacy: '',
      gtc: ''
    }
  },
  // 'develop.branches.360.koality.io': {
  //   login: ['default'],
  //   useSessionLogin: true,
  //   isPartner: false,
  //   ssoLoginUrl: 'https://platform360.staging.plesk.tech/auth/login',
  //   baseUrl: {
  //     dev: 'develop.branches.360.koality.io',
  //     stage: 'develop.branches.360.koality.io',
  //     production: 'develop.branches.360.koality.io'
  //   },
  //   externalWlDomain: 'https://monitoring.my-hosting.world',
  //   metaLinks: {
  //     imprint: '',
  //     privacy: '',
  //     gtc: ''
  //   }
  // },
  'sitecheck.my-hosting.world': {
    login: ['default'],
    useSessionLogin: true,
    isPartner: true,
    ssoLoginUrl: 'https://monitoring.my-hosting.world/signin?redirectUrl=',
    baseUrl: {
      dev: 'https://sitecheck.my-hosting.world',
      stage: 'https://sitecheck.my-hosting.world',
      production: 'https://sitecheck.my-hosting.world'
    },
    externalWlDomain: 'https://monitoring.my-hosting.world',
    metaLinks: {
      imprint: '',
      privacy: '',
      gtc: ''
    }
  },
  'monitoring.my-hosting.world': {
    login: ['default'],
    useSessionLogin: true,
    isPartner: true,
    ssoLoginUrl: 'https://monitoring.my-hosting.world/signin?redirectUrl=',
    baseUrl: {
      dev: 'https://sitecheck.my-hosting.world',
      stage: 'https://sitecheck.my-hosting.world',
      production: 'https://monitoring.my-hosting.world'
    },
    externalWlDomain: 'https://monitoring.my-hosting.world',
    metaLinks: {
      imprint: '',
      privacy: '',
      gtc: ''
    }
  },
  'ionos.my-hosting.world': {
    login: ['default'],
    useSessionLogin: true,
    isPartner: true,
    ssoLoginUrl: 'https://ionos.my-hosting.world/signin?redirectUrl=',
    baseUrl: {
      dev: 'https://ionos.my-hosting.world',
      stage: 'https://ionos.my-hosting.world',
      production: 'https://ionos.my-hosting.world'
    },
    externalWlDomain: 'https://ionos.my-hosting.world',
    metaLinks: {
      imprint: '',
      privacy: '',
      gtc: ''
    }
  },
  localhost: {
    login: ['default'],
    cname: 'sitecheck.platform360.io',
    companyName: 'My Hosting',
    partnerType: 'partner',
    tenantRealm: 'myhosting',
    signUpWithLicense: false,
    slug: 'myhosting',
    ssoAuthStrategy: null,
    ssoSignInUrl: null,
    ssoSignOutUrl: null,
    ssoSignUpUrl: null,
    useSessionLogin: false,
    ssoLoginUrl: '',
    storeUrl: 'http://shop.my-hosting.world',
    isPartner: true,
    baseUrl: {
      dev: 'http://localhost:3000',
      stage: 'http://localhost:3000',
      production: 'http://localhost:3000'
    },
    externalWlDomain: 'https://monitoring.my-hosting.world',
    metaLinks: {
      imprint: '',
      privacy: '',
      gtc: ''
    }
  },
  'stage.sitecheck.koality.io': {
    login: ['default'],
    useSessionLogin: true,
    ssoLoginUrl: 'https://auth.stage.koality.io/connect/360',
    metaLinks: {
      imprint: '',
      privacy: '',
      gtc: ''
    }
  },
  'monitor.koality.io': {
    login: ['default', 'haendlerbund', 'google'],
    metaLinks: {
      imprint: 'https://www.koality.io/de/imprint',
      privacy: 'https://www.koality.io/de/privacy',
      gtc: 'https://www.koality.io/de/termsofuse'
    }
  },
  'power-netz.koality.io': {
    login: ['default', 'haendlerbund', 'google'],
    metaLinks: {
      imprint: '',
      privacy: '',
      gtc: ''
    }
  },
  'siteocity.koality.io': {
    login: ['default'],
    metaLinks: {
      imprint: '',
      privacy: '',
      gtc: ''
    }
  },
  'hostpress.koality.io': {
    login: ['default'],
    metaLinks: {
      imprint: '',
      privacy: '',
      gtc: ''
    }
  },
  'typo3.koality.io': {
    login: ['default'],
    metaLinks: {
      imprint: '',
      privacy: '',
      gtc: ''
    }
  },
  'strato.koality.io': {
    login: ['default'],
    metaLinks: {
      imprint: '',
      privacy: '',
      gtc: ''
    }
  },
  'cpanel.koality.io': {
    login: ['default'],
    metaLinks: {
      imprint: '',
      privacy: '',
      gtc: ''
    }
  },
  'monitoramento.locaweb.com.br': {
    login: ['default'],
    useSessionLogin: true,
    isPartner: true,
    ssoLoginUrl: 'https://monitoramento.locaweb.com.br/signin?redirectUrl=',
    baseUrl: {
      dev: 'https://monitoramento.locaweb.com.br',
      stage: 'https://monitoramento.locaweb.com.br',
      production: 'https://monitoramento.locaweb.com.br'
    },
    externalWlDomain: 'https://monitoramento.locaweb.com.br',
    metaLinks: {
      imprint: '',
      privacy: '',
      gtc: ''
    }
  },
  '360monitoring.inmotionhosting.com': {
    login: ['default'],
    useSessionLogin: true,
    isPartner: true,
    ssoLoginUrl: 'https://360monitoring.inmotionhosting.com/signin?redirectUrl=',
    baseUrl: {
      dev: 'https://360monitoring.inmotionhosting.com',
      stage: 'https://360monitoring.inmotionhosting.com',
      production: 'https://360monitoring.inmotionhosting.com'
    },
    externalWlDomain: 'https://360monitoring.inmotionhosting.com',
    metaLinks: {
      imprint: '',
      privacy: '',
      gtc: ''
    }
  },
  'monitor.ramnode.com': {
    login: ['default'],
    useSessionLogin: true,
    isPartner: true,
    ssoLoginUrl: 'https://monitor.ramnode.com/signin?redirectUrl=',
    baseUrl: {
      dev: 'https://monitor.ramnode.com',
      stage: 'https://monitor.ramnode.com',
      production: 'https://monitor.ramnode.com'
    },
    externalWlDomain: 'https://monitor.ramnode.com',
    metaLinks: {
      imprint: '',
      privacy: '',
      gtc: ''
    }
  },
  'guardian.easyonnet.io': {
    login: ['default'],
    useSessionLogin: true,
    isPartner: true,
    ssoLoginUrl: 'https://guardian.easyonnet.io/signin?redirectUrl=',
    baseUrl: {
      dev: 'https://guardian.easyonnet.io',
      stage: 'https://guardian.easyonnet.io',
      production: 'https://guardian.easyonnet.io'
    },
    externalWlDomain: 'https://guardian.easyonnet.io',
    metaLinks: {
      imprint: '',
      privacy: '',
      gtc: ''
    }
  },
  'monitoring.nuxit.com': {
    login: ['default'],
    useSessionLogin: true,
    isPartner: true,
    ssoLoginUrl: 'https://monitoring.nuxit.com/signin?redirectUrl=',
    baseUrl: {
      dev: 'https://monitoring.nuxit.com',
      stage: 'https://monitoring.nuxit.com',
      production: 'https://monitoring.nuxit.com'
    },
    externalWlDomain: 'https://monitoring.nuxit.com',
    metaLinks: {
      imprint: '',
      privacy: '',
      gtc: ''
    }
  }
}
export const fallbackCname = 'sitecheck.platform360.io'
export const localEnvironments = 'sitecheck.platform360.io'
export const partnerDomains = ['monitoring.my-hosting.world']
export const ssoLogins = {
  'monitoring.my-hosting.world': 'https://monitoring.my-hosting.world/signin?redirectUrl='
}
