const checkFeatures = (availableFeatures, featuresToCheck) => {
  if (availableFeatures) {
    const checks = availableFeatures
      .map((val) => {
        return featuresToCheck.includes(val)
      })
      .filter(val => !val)
      .length
    return !(checks > 0)
  }
  return true
}

const flatNavigation = (nav, features) => {
  const results = {}

  nav.forEach((val) => {
    let projectRole = val.access ?? null
    let companyRole = val.companyAccess ?? null
    let availability = val.featuresCheck ? checkFeatures(val.featuresCheck, features) : true

    val.groups.forEach((group) => {
      if (group.access) { projectRole = group.access || null }
      if (group.companyAccess) { companyRole = group.companyAccess || null }
      if (group.featuresCheck) { availability = checkFeatures(group.featuresCheck, features) }
      group.entries.forEach((entry) => {
        if (!entry.key) return

        if (entry.access) { projectRole = entry.access || null }
        if (entry.companyAccess) { companyRole = entry.companyAccess || null }
        if (entry.featuresCheck) { availability = checkFeatures(entry.featuresCheck, features) }
        results[entry.key] = {
          mainId: val.id,
          groupId: group.id,
          entryId: entry.id,
          breadcrumb: entry.breadcrumb || null,
          pageName: entry.pageName || null,
          projectRole,
          companyRole,
          availability,
          condition: val.condition || null
        }
      })
    })
  })

  return results
}

const listNavigationAliases = (nav) => {
  const results = {}
  const writeData = (id, name, path) => {
    results[id] = { name }
    results[id].path = path || null
  }

  nav.forEach((val) => {
    if (val.alias) {
      writeData(val.id, val.label, val.path)
    }
    val.groups.forEach((group) => {
      if (group.alias) {
        writeData(group.id, group.groupName, group.path)
      }

      group.entries.forEach((entry) => {
        if (entry.alias) {
          writeData(entry.id, entry.name, entry.path)
        }
      })
    })
  })

  return results
}

const generateNavigation = (navigation, features) => {
  let filterdNav = []

  const checkOnEntryLevel = (entries, features, checkFeatures) => {
    return entries.reduce((acc, cur) => {
      const { featuresCheck, ...entryData } = cur
      if (!cur.featuresCheck || checkFeatures(cur.featuresCheck, features)) {
        acc = [...acc, entryData]
      }
      return acc
    }, [])
  }

  const checkOnGroupLevel = (main, features, checkFeatures) => {
    const { groups } = main
    return groups.reduce((acc, cur) => {
      const { entries, featuresCheck, ...groupData } = cur
      if (!cur.featuresCheck || checkFeatures(cur.featuresCheck, features)) {
        acc = [...acc,
          { ...groupData, entries: checkOnEntryLevel(entries, features, checkFeatures) }
        ]
      }
      return acc
    }, [])
  }

  navigation.forEach((main) => {
    const { groups, ...mainData } = main
    if (!main.featuresCheck || checkFeatures(main.featuresCheck, features)) {
      filterdNav = [
        ...filterdNav,
        {
          ...mainData,
          groups: checkOnGroupLevel(main, features, checkFeatures)
        }]
    }
  })

  return filterdNav
}

// const checkNavAccess = (navDetails, userDetails, projectRole, isDemouser = false) => {
//   if (isDemouser && !!navDetails.noAccessForDemo) return false
//
//   if (navDetails.companyAccess && userDetails?.company?.role) {
//     return navDetails.companyAccess >= userDetails.company.role.id
//   } else {
//     return (!navDetails.access || projectRole === 0)
//       ? true
//       : navDetails.access >= projectRole
//   }
// }

const checkNavAccess = (navDetails, userDetails, projectRole, isDemouser = false) => {
  if (isDemouser && !!navDetails.noAccessForDemo) return false

  if (navDetails.companyAccess && userDetails?.company?.role) {
    if (!navDetails.access || projectRole === 0) {
      return navDetails.companyAccess >= userDetails.company.role.id
    } else if (!navDetails.access.length) {
      return (projectRole <= navDetails.access)
    } else {
      return true
    }
  } else {
    return (!navDetails.access || projectRole === 0)
      ? true
      : navDetails.access >= projectRole
  }
}

const linkGenerator = (path, pid, sid) => {
  const fragments = path.split('/')
  if (fragments.length > 1 && fragments[1] === '[psid]') {
    const end = fragments.slice(2).join('/')
    return `/${pid}/${sid}/${end}`
  } else {
    return path
  }
}

export {
  flatNavigation,
  listNavigationAliases,
  generateNavigation,
  checkNavAccess,
  linkGenerator
}
