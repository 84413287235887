const incidentSections = [
  {
    id: 'lighthouse_desktop_performance',
    name: 'lighthouseMobilePerformance',
    link: 'performanceLighthouse',
    path: '/[psid]/monitoring/performance/lighthouse',
    group: 'performance'
  },
  {
    id: 'performanceLoad',
    name: 'performanceLoad',
    link: 'performanceServertimings',
    path: '/[psid]/monitoring/performance/servertimings',
    group: 'performance'
  },
  {
    id: 'lighthouse_mobile_performance',
    name: 'lighthouseDesktopPerformance',
    link: 'performanceLighthouse',
    path: '/[psid]/monitoring/performance/lighthouse',
    group: 'performance'
  },
  {
    id: 'performance',
    name: 'performance',
    link: 'performanceBrowsertimings',
    path: '/[psid]/monitoring/performance/browsertimings',
    group: 'performance'
  },
  {
    id: 'BrokenLink',
    name: 'brokenLink',
    link: 'contentBroken',
    path: '/[psid]/monitoring/content/contentBroken',
    group: 'content'
  },
  {
    id: 'DeadLink',
    name: 'deadLink',
    link: 'contentBroken',
    path: '/[psid]/monitoring/content/contentBroken',
    group: 'content'
  },
  {
    id: 'lighthouse_desktop_seo',
    name: 'lighthouse',
    link: 'seoScore',
    path: '/[psid]/monitoring/seo/seoScore',
    group: 'seo'
  },
  {
    id: 'lighthouse_mobile_seo',
    name: 'lighthouse',
    link: 'seoScore',
    path: '/[psid]/monitoring/seo/seoScore',
    group: 'seo'
  },
  {
    id: 'EsLinter',
    name: 'eslint',
    link: '',
    path: '',
    group: 'general'
  },
  {
    id: 'Lighthouse',
    name: 'lighthouse',
    link: 'seoScore',
    path: '/[psid]/monitoring/seo/seoScore',
    group: 'seo'
  },
  {
    id: 'koalamon_intervalcheck',
    name: 'intervalCheck',
    link: '',
    path: '',
    group: 'general'
  },
  {
    id: 'HealthChecker',
    name: 'healthCheck',
    link: '',
    path: '',
    group: 'general'
  },
  {
    id: 'ElementChecker',
    name: 'elementChecker',
    link: '',
    path: '',
    group: 'general'
  },
  {
    id: 'httphead',
    name: 'httpHead',
    link: '',
    path: '',
    group: 'general'
  },
  {
    id: 'SiteInfoFileSize',
    name: 'pageSize',
    link: 'performancePagesize',
    path: '/[psid]/monitoring/performance/pagesize',
    group: 'performance'
  },
  {
    id: 'pagespeedscore',
    name: 'pageSpeed',
    link: '',
    path: '',
    group: 'general'
  },
  {
    id: 'xpathchecker',
    name: 'xpathCheck',
    link: '',
    path: '',
    group: 'general'
  },
  {
    id: 'JsonValidator',
    name: 'jsonValidator',
    link: '',
    path: '',
    group: 'general'
  },
  {
    id: 'regexexists',
    name: 'regexexists',
    link: '',
    path: '',
    group: 'general'
  },
  {
    id: 'JsErrorScanner',
    name: 'jsErrors',
    link: 'technicalJavascript',
    path: '/[psid]/monitoring/technical/javascript',
    group: 'technical'
  },
  {
    id: 'KoalaPing',
    name: 'uptime',
    link: 'uptimeStats',
    path: '/[psid]/monitoring/uptime/uptimeStats',
    group: 'uptime'
  },
  {
    id: 'LittleSeo',
    name: 'littleSeo',
    link: '',
    path: '',
    group: 'general'
  },
  {
    id: 'SiteInfoBigFile',
    name: 'bigFiles',
    link: 'performancePagesize',
    path: '/[psid]/monitoring/perfoamce/pagesize',
    group: 'performance'
  },
  {
    id: 'HttpCertExpire',
    name: 'httpsCerts',
    link: 'securityCerts',
    path: '/[psid]/monitoring/security/certs',
    group: 'security'
  },
  {
    id: 'XmlWellFormed',
    name: 'xmlWellFormed',
    link: '',
    path: '',
    group: 'general'
  },
  {
    id: 'Sitemap',
    name: 'sitemap',
    link: 'seoSitemapMobile',
    path: '/[psid]/monitoring/seo/sitemapMobile',
    group: 'seo'
  },
  {
    id: 'cssSelectorChecker',
    name: 'cssSelectorChecker',
    link: '',
    path: '',
    group: 'general'
  },
  {
    id: 'xmlvalidxsd',
    name: 'validXsd',
    link: '',
    path: '',
    group: 'general'
  },
  {
    id: 'jsonPathChecker',
    name: 'jsonPathChecker',
    link: '',
    path: '',
    group: 'general'
  },
  {
    id: 'HttpCertAuthority',
    name: 'httpCertAuthority',
    link: '',
    path: '',
    group: 'general'
  },
  {
    id: 'MobileFriendly',
    name: 'mobileFriendly',
    link: 'seoSitemapMobile',
    path: '/[psid]/monitoring/seo/sitemapMobile',
    group: 'seo'
  },
  {
    id: 'jsonschema',
    name: 'jsonSchema',
    link: '',
    path: '',
    group: 'general'
  },
  {
    id: 'MissingRequest2',
    name: 'missingRequest',
    link: '',
    path: '',
    group: 'general'
  },
  {
    id: 'ResourcesCount',
    name: 'resourceCount',
    link: '',
    path: '',
    group: 'general'
  },
  {
    id: 'insecurecontent',
    name: 'insecureContent',
    link: '',
    path: '',
    group: 'general'
  },
  {
    id: 'rssValidator',
    name: 'rssValidator',
    link: '',
    path: '',
    group: 'general'
  },
  {
    id: 'redirectChecker',
    name: 'redirectChecker',
    link: '',
    path: '',
    group: 'general'
  },
  {
    id: 'CookieCount',
    name: 'cookieCount',
    link: 'securityCookies',
    path: '/[psid]/monitoring/security/cookies',
    group: 'security'
  },
  {
    id: 'BrokenLinkExternal',
    name: 'brokenLinkExternal',
    link: '',
    path: '',
    group: 'general'
  },
  {
    id: 'BrokenLink_external',
    name: 'brokenLinkExternal',
    link: '',
    path: '',
    group: 'general'
  },
  {
    id: 'JsErrorScanner_external',
    name: 'externalJsErrorScanner',
    link: '',
    path: '',
    group: 'general'
  },
  {
    id: 'CssValidator',
    name: 'cssValidator',
    link: '',
    path: '',
    group: 'general'
  },
  {
    id: 'lighthouse_desktop_best-practices',
    name: 'lighthouseDesktopBestPractices',
    link: '',
    path: '',
    group: 'general'
  },
  {
    id: 'lighthouse_mobile_best-practices',
    name: 'lighthouseMobileBestPractices',
    link: '',
    path: '',
    group: 'general'
  }
]

const incidentGroupNames = [...new Set(incidentSections.map(val => val.group))].filter(val => val).sort(a => a === 'general' ? 1 : -1)

const incidentIds = incidentGroupNames.reduce((a, b) => ({ ...a, [b]: [...new Set(incidentSections.filter(val => val.group === b).map(val => val.id))] }), {})

const incidentIdList = Object.values(incidentIds).flat()

export {
  incidentIdList,
  incidentGroupNames,
  incidentSections,
  incidentIds
}
